// src/router/index.js
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/index'),
  }
];

const router = new VueRouter({
  base: '',
  mode: 'hash',
  routes,
});

router.beforeEach((to, from, next) => {
  // 在导航前重置滚动位置
  window.scrollTo(0, 0);
  next();
});

export default router;
