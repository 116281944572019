<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.black14_Medium {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  line-height: 20px;
  margin: 0;
  padding: 0;
}

.black14_Regular {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 500;
  color: #222222;
  line-height: 20px;
  margin: 0;
}

.white14_Regular {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 20px;
  margin: 0;
}

.gray14_Regular {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  margin: 0;
}

.gray12_Regular {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666660;
  line-height: 17px;
  margin: 0;
}

.top10 {
  margin-top: 10px;
}

.top5 {
  margin-top: 5px;
}

.bottom10 {
  margin-bottom: 10px;
}

.left10 {
  margin-left: 10px;
}

.right10 {
  margin-right: 10px;
}


</style>
